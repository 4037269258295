<template>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      @input="check"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        snackbar: false
    }),
    methods: {
        show(){
            this.snackbar = true;
        },
        check(e){
            console.log(e);
        }
    }
};
</script>